<template>
    <div>
        <div class="header">
            <img src="/wonde.ico" alt="" @click="goIndex">
            <div class="header-body">
                <input placeholder="keywords" v-model="searchText" type="text">
                <div @click="search">search</div>
            </div>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'

    export default {
        data() {
            return {
                searchText:''
            }
        },
        mounted() {
            eventBus.$on('showfenlei',i=>{
                this.showMenu = i
            })
        },
        methods: {
            goIndex(){
                if(this.$route.path!='/'){
                    this.$router.push('/')
                }else{
                    this.$router.go(0)
                }
            },
            search(){
                if(this.$route.path=='/selectGame'){
                    eventBus.$emit('searchgame',{
                        id: 'search',
                        key:this.searchText
                    })
                }else{
                    this.$router.push({
                        path:'/selectGame',
                        query:{
                            id:'search',
                            key:this.searchText
                        }

                    })
                }
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .header{
    width: 1440px;
    transform: translateX(-60px);
    height: 55px;
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .header{
    width: 100vw;
  }
}
.header{
    position: fixed;
    top: 0;
    background-color: #FFF;
    z-index: 99999;
    >img{
        width: 50px;
        float: left;
    }
    .header-body{
        width: 260px;
        height: 55px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        input{
            &::placeholder {
                font-family: Inter, Inter;
                font-weight: normal;
                font-size: 16px;
                color: #BFC2C2;
                line-height: 19px;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }
            display: block;
            padding-left: 8px;
            box-sizing: border-box;
            width: 180px;
            height: 35px;
            background: #FFFFFF;
            border-radius: 6px 6px 6px 6px;
            border: 1px solid #CFD4D8;
        }
        div{
            margin-left: 8px;
            width: 70px;
            height: 35px;
            line-height: 35px;
            background: #5EB5F9;
            border-radius: 5px 5px 5px 5px;
            border: 0px solid #000000;
            cursor: pointer;
            font-family: Inter, Inter;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            text-align: center;
            font-style: normal;
            text-transform: none;
        }
    }
}
</style>