var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"class-body"},[_vm._l((_vm.classList),function(item){return _c('div',{key:item.category,class:{
            'class-card':true,
            's-card':_vm.selectClass == item.key_name,
            'pointer':true
        },on:{"click":function($event){return _vm.getClassName(item.key_name)}}},[_c('div',{staticClass:"card-body"},[_c('img',{attrs:{"src":item.url,"alt":""}}),_c('p',[_vm._v(_vm._s(item.key_name))])])])}),_vm._l((3),function(item){return _c('span',{key:item,staticStyle:{"width":"70px"}})})],2),_c('adContent'),_c('div',{staticClass:"division"}),_c('div',{staticClass:"game-list"},_vm._l((_vm.gameList),function(item){return _c('div',{key:item.id,class:{
            'game-card':true,
            'm-padding':_vm.isMobile,
            'w-padding':!_vm.isMobile,
            'pointer':true
        },on:{"click":function($event){return _vm.goDetails(item.id)}}},[(!_vm.isMobile)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-l"},[_c('img',{attrs:{"src":item.logo,"alt":""}})]),_c('div',{staticClass:"card-r"},[_c('div',{staticClass:"c-r-body"},[_c('div',{staticClass:"b-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"b-key"},[_vm._v(" "+_vm._s(item.key_name)+" ")])]),_c('p',{staticClass:"c-synopsis"},[_vm._v(_vm._s(item.synopsis))])])]):_c('div',{staticClass:"m-body"},[_c('img',{attrs:{"src":item.logo,"alt":""}}),(_vm.isMobile)?_c('p',{staticClass:"m-title"},[_vm._v(_vm._s(item.title))]):_vm._e(),(_vm.isMobile)?_c('p',{staticClass:"m-syn"},[_vm._v(_vm._s(item.synopsis))]):_vm._e()]),_c('div',{staticClass:"card-play"},[_vm._v(" PLAY ")])])}),0),_c('div',{staticClass:"game-blurb noselect"},[_vm._v(" "+_vm._s(_vm.blurbUrl)+" is a community-driven gaming platform that hosts over 130,000 free online games. The site caters to a wide range of gamers, from casual players to hardcore enthusiasts. Kongregate's diverse game selection includes everything from retro arcade classics to modern indie darlings. In addition to its extensive game library, Kongregate also offers a range of social features, including user profiles, friend lists, and game-specific forums. The site's Badges and Achievements system encourages players to explore new games and challenge themselves. Kongregate's commitment to supporting independent game developers has helped it become a hub for creative and innovative gameplay experiences. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }