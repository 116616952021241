import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index'
import details from '@/views/details'
import aboutus from '@/views/aboutus'
import terms from '@/views/terms'
import privacy from '@/views/privacy'
import selectGame from '@/views/selectGame'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/details',
    name: 'details',
    component: details
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: aboutus
  },
  {
    path: '/terms',
    name: 'terms',
    component: terms
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy
  },
  {
    path: '/selectGame',
    name: 'selectGame',
    component: selectGame
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

router.beforeEach((to,from,next)=>{
  setTimeout(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },0)
  next();
})

export default router
