<template>
    <div>
        <div class="class-body">
            <div :class="{
                'class-card':true,
                's-card':selectClass == item.key_name,
                'pointer':true
            }" v-for="item in classList" :key="item.category" @click="getClassName(item.key_name)">
                <div class="card-body">
                    <img :src="item.url" alt="">
                    <p>{{item.key_name}}</p>
                </div>
            </div>
            <span style="width:70px" v-for="item in 3" :key="item"></span>
        </div>
        <adContent></adContent>
        <div class="division"></div>
        <div class="game-list">
            <div :class="{
                'game-card':true,
                'm-padding':isMobile,
                'w-padding':!isMobile,
                'pointer':true
            }" v-for="item in gameList" :key="item.id" @click="goDetails(item.id)">
                <div class="card-body"  v-if="!isMobile">
                    <div class="card-l">
                        <img :src="item.logo" alt="">
                    </div>
                    <div class="card-r">
                        <div class="c-r-body">
                            <div class="b-title">
                                {{item.title}}
                            </div>
                            <div class="b-key">
                                {{item.key_name}}
                            </div>
                        </div>
                        <p class="c-synopsis">{{item.synopsis}}</p>
                    </div>
                </div>
                <div class="m-body" v-else>
                    <img :src="item.logo" alt="">
                    <p v-if="isMobile" class="m-title">{{item.title}}</p>
                    <p v-if="isMobile" class="m-syn">{{item.synopsis}}</p>
                </div>
                <div class="card-play">
                    PLAY
                </div>
            </div>
        </div>
        <div class="game-blurb noselect">
            {{ blurbUrl }} is a community-driven gaming platform that hosts over 130,000 free online games. The site caters to a wide range of gamers, from casual players to hardcore enthusiasts. Kongregate's diverse game selection includes everything from retro arcade classics to modern indie darlings. In addition to its extensive game library, Kongregate also offers a range of social features, including user profiles, friend lists, and game-specific forums. The site's Badges and Achievements system encourages players to explore new games and challenge themselves. Kongregate's commitment to supporting independent game developers has helped it become a hub for creative and innovative gameplay experiences.
        </div>
    </div>
</template>

<script>
import navMenu from '@/utils/navMenu'
import { isMobile } from "@/utils/api";
import { searchGame, getClassList, getGame } from "@/api/games"
import adContent from "@/components/adContent/index.vue";
    export default {
        components: {
            adContent,
        },
        data() {
            return {
                gameList: [],
                classList: [],
                selectClass: 'New',
                isMobile:false,
                blurbUrl: process.env.VUE_APP_BASE_GAME
            }
        },
        mounted() {
            let name = this.$route.params.searchtext;
            if(name){
                this.searchGame(name);
            }else{
                this.getList();
            }
            this.getClassList();
            this.isMobile = isMobile();
            console.log('000',window.location.hostname)
        },
        methods: {
            async searchGame(name){
                let res = await searchGame({q:name});
                this.gameList = res;
            },
            async getList(){
                let res = await getGame();
                this.gameList = res;
            },
            async getClassList(){
                let classlist = await getClassList();
                classlist.forEach(el => {
                    navMenu.forEach(item=>{
                        if(item.name==el.key_name){
                            el.url = item.url
                        }
                    })
                });
                this.classList = classlist;
            },
            async getClassName(category){
                this.selectClass = category;
                this.$router.push({
                    path:'/selectGame',
                    query:{
                        id:'more',
                        key:category
                    }
                })
            },
            goDetails(id){
                this.$router.push({
                    path:'/details',
                    query:{
                        id
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.class-body{
    width: 100%;
    white-space: nowrap;
    //display: flex;
    //justify-content: space-between;
    margin-top: 54px;
    overflow: auto;
    .class-card{
        display: inline-block;
        width: 70px;
        height: 90px;
        border-radius: 0px 0px 0px 0px;
        text-align: center;
        position: relative;
        .card-body{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            img{
                width: 30px;
                height: 30px;
            }
            p{
                font-family: Inter, Inter;
                font-weight: bold;
                font-size: 12px;
                color: #FFFFFF;
                font-style: normal;
                text-transform: none;
            }
        }
    }
    .s-card{
        background: #3B9FEE;
    }
}
.division{
    margin: 31px 0 20px;
    border: 1px solid #FFFFFF;
}
.game-list{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding: 0 5px;
    box-sizing: border-box;
    grid-gap: 20px;
    .m-padding{
        padding: 10px 0;
    }
    .w-padding{
        padding: 19px 18px;
    }
    .game-card{
        margin-bottom: 20px;
        background: linear-gradient( 90deg, #90CCFA 0%, #CBE5FA 100%);
        border-radius: 9px 9px 9px 9px;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        // filter: blur(4px);
        .card-body{
            display: flex;
            .card-l{
                width: 80px;
                height: 80px;
                img{
                    width: 80px;
                    height: 80px;
                }
            }
            .card-r{
                margin-left: 14px;
                .c-r-body{
                    // width: 525px;
                    height: 18px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .b-title{

                        font-family: Inter, Inter;
                        font-weight: bold;
                        font-size: 16px;
                        color: #3C3434;
                        text-align: left;
                        font-style: normal;
                        text-transform: none;
                    }
                    .b-key{
                        background-color: #DF8138;
                        width: 71px;
                        height: 15px;
                        font-family: Inter, Inter;
                        font-weight: bold;
                        font-size: 12px;
                        color: #FFFFFF;
                        line-height: 14px;
                        text-align: center;
                        font-style: normal;
                        text-transform: none;
                    }
                }
                .c-synopsis{
                    padding: 0;
                    margin: 0;

                    font-family: Inter, Inter;
                    font-weight: 400;
                    font-size: 12px;
                    color: #3C3434;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .m-body{
            img{
                border-radius: 5px;
                display: block;
                width: 80px;
                height: 80px;
                margin: 0 auto;
            }
            .m-title{
                margin: 10px 0;
                text-align: center;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .m-syn{
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .card-play{
            width: 70px;
            height: 35px;
            text-align: center;
            line-height: 35px;
            margin: 10px auto 0;
            background-color: #3B9FEE;
            color: #FFF;
            border-radius: 10px;
        }
    }
}
.game-blurb{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>